import { mandatory, nonMandatory } from '../storeFromRequestParamsValidation';

export const oneTrustConsentCustomizationNavigationButton: LinkButton = {
  text: 'Cookie Settings',
  onClick: null,
  cssClassName: 'ot-sdk-show-settings',
  attributes: null,
} as const;

export interface Link {
  text: string;
  href: string;
}
export const createBaseLinkInitialState = (link: any): Link => {
  return {
    text: mandatory(link.text, ''),
    href: mandatory(link.url, ''),
  };
};

export interface NavigationLink {
  link: Link;
  children: NavigationLink[] | null;
}
export const createNavigationLinksInitialState = (navigationLinks: any): NavigationLink => {
  return {
    link: mandatory(navigationLinks.link, {}, createBaseLinkInitialState),
    children: nonMandatory(navigationLinks.children, (childrenNavigationLinks: any) => (childrenNavigationLinks ? childrenNavigationLinks.map(createNavigationLinksInitialState) : [])),
  };
};

export const getViewBoxValue = (svg: string) => {
  const regex = /viewBox="\s*(.*?)\s*"/g;
  const regexResult = regex.exec(svg);
  return regexResult ? regexResult[1] : '';
};

export const createLogo = (logo: any) => {
  const svgLarge = mandatory(logo.largeScreenLogo, '');
  const svgSmall = mandatory(logo.smallScreenLogo, '');
  return {
    svgLarge,
    svgSmall,
    svgLargeViewBox: getViewBoxValue(svgLarge),
    svgSmallViewBox: getViewBoxValue(svgSmall),
    alt: mandatory(logo.alt, ''),
    href: mandatory(logo.url, ''),
  };
};

export interface Svg {
  src: string;
  svgViewBox: string;
  alt: string;
}
export const createSvg = (svg: any): Svg => {
  const src = mandatory(svg.src, '');
  return {
    src,
    svgViewBox: getViewBoxValue(src),
    alt: mandatory(svg.alt, ''),
  };
};

export interface SvgLink {
  src: string;
  svgViewBox: string;
  alt: string;
  href: string;
}
export const createLinkSvg = (svg: any): SvgLink => {
  const src = mandatory(svg.src, '');
  return {
    src,
    svgViewBox: getViewBoxValue(src),
    alt: mandatory(svg.alt, ''),
    href: mandatory(svg.url, ''),
  };
};

export type ImageLink = {
  alt: string;
  href: string;
  src: string;
};
export const createImageLinkInitialState = (image: any): ImageLink => ({
  alt: mandatory(image.alt, ''),
  href: mandatory(image.url, ''),
  src: mandatory(image.src, ''),
});

export interface LinkButton {
  text: string;
  onClick: string | null;
  attributes: {[key: string]: string} | null;
  cssClassName: string | null;
}

export interface InjectButtonParams {
  text: string;
  onClick?: string;
  attributes?: {[key: string]: string};
  cssClassName?: string;
}
